import moment from "moment";
import { toast } from "react-toastify";
import { HttpUnauthorizedResponseError, HttpUnexpectedResponseError } from "./exceptions";
import ValidationResult from "./validationResult";

function isObject(value: unknown) {
  return Object.prototype.toString.call(value) === "[object Object]";
}

const isoStringsToDates = (object: unknown) => {

  const isValidISODate = (value: string): boolean => {
    return moment(value, moment.ISO_8601, true).isValid();
  };

  const hasNonZeroTimeComponent = (value: string): boolean => {
    return /T(?!00:00:00)/.test(value); // Check for any time component other than T00:00:00
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resolveValue = (value: any, key: string | number, object: any) => {
    if (typeof value === "string" && isValidISODate(value)) {
      object[key] = hasNonZeroTimeComponent(value)
        ? moment.utc(value).toDate()
        : moment(value).toDate(); // Uses browser's timezone if time component is T00:00:00 or missing
    } else if (Array.isArray(value) || isObject(value)) {
      go(value);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const go = (object: any) => {
    if (Array.isArray(object)) {
      object.forEach((value, i, array) => resolveValue(value, i, array));
    } else if (isObject(object)) {
      Object.keys(object).forEach(key => resolveValue(object[key], key, object));
    }
  };

  go(object);
};

export default async function processHttpResponse(httpResponse: Response, options: { automaticSuccessNotification: boolean; }) {
  switch (httpResponse.status) {
    case 200:
      var contentType = httpResponse.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        return httpResponse.json().then((data) => {
          isoStringsToDates(data);
          return Promise.resolve(data);
        });
      } else {
        return httpResponse.blob().then(x => Promise.resolve(x));
      }
    case 201:
      if (options.automaticSuccessNotification)
        toast.success("Operación exitosa", { autoClose: 3 * 1000 });
      return httpResponse.json().then((data) => {
        isoStringsToDates(data);
        return Promise.resolve(data);
      });
    case 302:
      return httpResponse.json().then((data) => {
        isoStringsToDates(data);
        return Promise.resolve(data);
      });
    case 204:
      if (options.automaticSuccessNotification)
        toast.success("Operación exitosa", { autoClose: 3 * 1000 });
      return Promise.resolve(null);
    case 404:
      toast.warning("Recurso no encontrado", { autoClose: 3 * 1000 });
      return Promise.resolve(null);
    case 400:
      return httpResponse.json().then(json => {
        if (json.message) {
          toast.error(json.message, { autoClose: 7 * 1000 });
        }
        else if (json.error && json.error.message) {
          toast.error(json.error.message, { autoClose: 7 * 1000 });
        } else {
          //HACK: The 'errors' property is used when the server returns an error through the CreateErrorResponse extension method
          const errorsList = json.errors || json;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          new ValidationResult(errorsList).errors.map((error: any) =>
            toast.error(error.errorMessage, { autoClose: 7 * 1000 })
          );
        }
        return Promise.reject("validation error");
      });
    case 401:
      throw new HttpUnauthorizedResponseError();
    case 500:
    case 502:
      toast.error("An unknown error was encountered", { autoClose: 7 * 1000 });
      return Promise.reject("internal server error / bad gateway");
    case 503:
      toast.error("Service unavailable", { autoClose: 7 * 1000 });
      return Promise.reject("service unavailable");
    default:
      return Promise.reject(new HttpUnexpectedResponseError(httpResponse));
  }
}
