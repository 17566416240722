import moment from "moment";
import "moment-timezone";

export const areDatesEqual = (date1: Date, date2: Date) => moment(date1).isSame(date2);
export const formatDate = (date: Date) => moment(date).format("LL");
export const formatDateLongMonth = (date: Date) => moment(date).format("MMMM DD, YYYY");
export const formatDateShortMonth = (date: Date) => moment(date).format("MMM DD, YYYY");
export const formatDateWithTime = (date: Date) => moment.utc(date).local().format("LLL");
export const isCurrentMonth = (date: Date) =>
    new Date().getMonth() === new Date(date).getMonth() &&
    new Date().getFullYear() === new Date(date).getFullYear();