import { DropdownNavItem, NavDropdown } from "core/components/NavDropdown";
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Nav } from "reactstrap";
import { isAdminUser, isCustomerManager } from "authorization/roles";

export default class MenuButtons extends React.PureComponent {
  static propTypes = {
    collapse: PropTypes.func.isRequired,
    userName: PropTypes.string.isRequired
  };

  render() {
    const { collapse, userName } = this.props;
    const membersMenu =
      <NavDropdown label="Miembros">
        <DropdownNavItem onClick={collapse} label="Nuevo miembro" to="/members/create" />
        <DropdownNavItem onClick={collapse} label="Ver miembros" to="/members" />
        <DropdownNavItem onClick={collapse} label="Ver accesos" to="/unlocks" />
      </NavDropdown>;
    const bookingsMenu =
      <NavDropdown label="Reservas">
        <DropdownNavItem onClick={collapse} label="Nueva reserva" to="/bookings/create" />
        <DropdownNavItem onClick={collapse} label="Ver reservas" to="/bookings" />
      </NavDropdown>;
    const customersMenu =
      <NavDropdown label="Clientes">
        <DropdownNavItem onClick={collapse} label="Nuevo cliente" to="/customers/create" />
        <DropdownNavItem onClick={collapse} label="Ver clientes" to="/customers" />
        <DropdownNavItem onClick={collapse} label="Ver servicios" to="/services" />
      </NavDropdown>;
    const managementMenu =
      <NavDropdown label="Gestión">
        <DropdownNavItem onClick={collapse} label="Precios" to="/management/prices" />
        <DropdownNavItem onClick={collapse} label="Email masivo" to="/management/broadcast" />
      </NavDropdown>;
    const userMenu =
      <NavDropdown label={userName}>
        <DropdownNavItem onClick={this.collapse} label="Salir" to="/logout" />
      </NavDropdown>;

    const canViewAllMenus = isAdminUser();
    const canViewCustomerUnlocksMenu = isCustomerManager();

    return (
      <Fragment>
        <Nav className="nav nav-fill d-none d-lg-flex d-xl-flex" navbar>
          {canViewAllMenus &&
            <>
              {customersMenu}
              {membersMenu}
            </>}
          {canViewCustomerUnlocksMenu &&
            <NavDropdown label="Miembros">
              <DropdownNavItem onClick={collapse} label="Ver accesos" to="/unlocks" />
            </NavDropdown>}
          {bookingsMenu}
          {canViewAllMenus && managementMenu}
        </Nav>
        <Nav className="ml-auto d-none d-lg-flex d-xl-flex" navbar>
          {userMenu}
        </Nav>
        <div className="row d-flex d-lg-none d-xl-none">
          <div className="col-6">
            <Nav className="ml-auto" navbar>
              {canViewAllMenus &&
                <>
                  {customersMenu}
                  {membersMenu}
                </>}
              {canViewCustomerUnlocksMenu &&
                <NavDropdown label="Miembros">
                  <DropdownNavItem onClick={collapse} label="Ver accesos" to="/unlocks" />
                </NavDropdown>}
              {bookingsMenu}
            </Nav>
          </div>
          <div className="col-6">
            <Nav className="ml-auto" navbar>
              {userMenu}
              {canViewAllMenus && managementMenu}
            </Nav>
          </div>
        </div>
      </Fragment>
    );
  }
}
