import PropTypes from "prop-types";
import React from "react";
import Select, { components } from "react-select";
import "./Dropdown.scss";
import { ValidableFeedback } from "./Validable";
import { allExceptIds } from "core/util/arrayUtilities";
import _ from "lodash";
import { callbackOnWidthChange } from "core/util/windowUtilities";

const CustomOption = props => {
  const { data } = props;
  const labelStyle = data.description ? "font-weight-bold" : null;
  const optionImage = data.imageUrl ? (
    <img className="img" src={data.imageUrl} alt="" />
  ) : null;
  return (
    <components.Option {...props}>
      <div className="custom-option media ">
        {optionImage}
        <div className="media-body">
          <div className={labelStyle}>{data.label}</div>
          {data.description && <div>{data.description}</div>}
        </div>
      </div>
    </components.Option>
  );
};

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.isSearchableCallback = this.isSearchableCallback.bind(this);
    this.state = {
      isSearchable: this.getIsSearchable(props.isAsync),
      width: window.innerWidth
    };
  }

  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.any.isRequired,
        label: PropTypes.string.isRequired,
        description: PropTypes.string
      })
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    selectedId: PropTypes.any,
    className: PropTypes.string,
    showDropdownIndicator: PropTypes.bool,
    mandatory: PropTypes.bool,
    isAsync: PropTypes.bool
  };

  static defaultProps = {
    showDropdownIndicator: true,
    mandatory: false,
    isAsync: false
  };

  getIsSearchable = (isAsync) => {
    return (window.innerWidth > 760 || isAsync);
  };

  componentDidMount() {
    if (!this.props.isAsync) {
      window.addEventListener("resize", _.debounce(this.setIsSearchable, 250));
    }
  };

  componentWillUnmount() {
    if (!this.props.isAsync) {
      window.removeEventListener("resize", _.debounce(this.setIsSearchable, 250));
    }
  };

  isSearchableCallback = newWidth => {
    this.setState({
      width: newWidth,
      isSearchable: this.getIsSearchable(false)
    });
  };

  setIsSearchable = () => {
    callbackOnWidthChange(this.state.width, this.isSearchableCallback);
  };

  render() {
    const {
      className,
      items,
      selectedId,
      onChange,
      showDropdownIndicator,
      readOnly,
      mandatory,
      ...rest
    } = this.props;
    const value = items.find(x => x.id === selectedId) || null;
    const selectClass = `react-select-container ${className}`;
    const invalid = mandatory && (
      selectedId === null ||
      selectedId === undefined
    );

    return (
      <React.Fragment>
        <Select
          isSearchable={this.state.isSearchable}
          classNamePrefix="react-select"
          className={`${selectClass} ${invalid && "invalid"} ${mandatory && invalid && "highlighted"}`}
          value={value}
          onChange={e => onChange(e ? e.id : null)}
          options={items}
          isDisabled={readOnly}
          components={{
            Option: CustomOption,
            DropdownIndicator: showDropdownIndicator
              ? components.DropdownIndicator
              : null
          }}
          {...rest}
        />
        <ValidableFeedback invalid={invalid} />
      </React.Fragment>
    );
  }
}

class AsyncDropdown extends React.Component {
  static propTypes = {
    fetchItems: PropTypes.func.isRequired,
    mapItemsToDropdownItems: PropTypes.func,
    initialSearchValue: PropTypes.string,
    exceptIds: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    prepopulateFromStorage: PropTypes.bool,
    selected: PropTypes.shape({
      id: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired
    })
  };

  static defaultProps = {
    mapItemsToDropdownItems: x => x
  };

  state = {
    items: [],
    currentSearchValue: ""
  };

  componentDidMount() {
    const { initialSearchValue, prepopulateFromStorage, selected } = this.props;
    if (initialSearchValue) {
      this.loadItems(initialSearchValue);
    }
    else if (prepopulateFromStorage && selected) {
      const { items } = this.state;
      items.push(selected);
      this.setState({ items });
    }
  }

  loadItems = async searchValue => {
    this.setState({
      isLoading: true
    });
    const items = await this.props.fetchItems(searchValue);
    this.setState({
      items: this.props.mapItemsToDropdownItems(items),
      isLoading: false
    });
  };

  requestLoadingItems = searchValue => {
    this.setState({ currentSearchValue: searchValue, isLoading: true });
    const timerId = window.setTimeout(() => {
      if (searchValue !== this.state.currentSearchValue) {
        return window.clearTimeout(timerId);
      }
      if (searchValue) this.loadItems(searchValue);
      else this.setState({ isLoading: false });
    }, 300);
  };

  render() {
    const { fetchItems, mapItemsToDropdownItems, exceptIds, onChange, prepopulateFromStorage, selected, selectedId, ...rest } = this.props;
    const filteredItems = exceptIds ? allExceptIds(this.state.items, exceptIds) : this.state.items;
    return (
      <Dropdown
        items={filteredItems}
        isLoading={this.state.isLoading}
        onInputChange={value => this.requestLoadingItems(value)}
        showDropdownIndicator={false}
        filterOption={false}
        isAsync={true}
        onChange={value => {
          if (!value) {
            // If the value is null then the user cleared the dropdown so we reset the option list.
            this.setState({ items: [] });
          }
          onChange(prepopulateFromStorage && value ? filteredItems.find(x => x.id === value) : value);
        }}
        noOptionsMessage={() => this.state.currentSearchValue ? "Sin opciones" : "Ingresa un término de búsqueda..."}
        selectedId={selected ? selected.id : selectedId}
        {...rest}
      />
    );
  }
}

class Item {
  constructor(id, label) {
    this.id = id;
    this.label = label;
  }
}

export { Dropdown, AsyncDropdown, Item };
export default Dropdown;
