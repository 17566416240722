import ListViewRowWrapper from "core/components/ListViewRowWrapper";
import Row from "components/bookings/BookingTableRow";
import PropTypes from "prop-types";
import React from "react";
import { isAdminUser } from "authorization/roles";

const Table = ({ bookings, loading, onBottomScroll }) => {
    const rows = bookings.map(x => <Row booking={x} key={x.id} />);
    const columns = ["Qué", "Quién?", "Desde", "Hasta"];
    if (isAdminUser()) columns.push("Notas");
    return (
        <table className="table table-hover">
            <thead><tr>{columns.map((x, idx) => <th key={idx}>{x}</th>)}</tr></thead>
            <tbody>
                <ListViewRowWrapper
                    rows={rows}
                    numberOfColumns={columns.length}
                    loading={loading}
                    onBottomScroll={onBottomScroll}
                />
            </tbody>
        </table>
    );
};

Table.propTypes = {
    bookings: PropTypes.arrayOf(PropTypes.any).isRequired,
    loading: PropTypes.bool.isRequired,
    onBottomScroll: PropTypes.func.isRequired
};

export default Table;
