import PropTypes from "prop-types";
import React from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import { Link } from "react-router-dom";

export const DropdownNavItem = ({ label, to, onClick }) => (
  <DropdownItem tag={Link} to={to} onClick={onClick}>
    {label}
  </DropdownItem>
);

export class NavDropdown extends React.Component {
  static propTypes = {
    children:
      PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
      ]).isRequired
  };

  state = {
    dropdownOpen: false
  };

  toggle = () =>
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));

  render() {
    return (
      <Dropdown
        nav
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle nav caret className="nav-item">{this.props.label}</DropdownToggle>
        <DropdownMenu>{this.props.children}</DropdownMenu>
      </Dropdown>
    );
  }
}
