import Filter from "core/filtering/filter";

export default class MemberFilter extends Filter {
    name: string;
    customerId?: number;
    memberTypeId?: number;
    onlyCurrent: boolean;
    constructor(filter?: { name: string; customerId?: number; memberTypeId?: number; onlyCurrent: boolean; }) {
        super({ $orderby: "CustomerName" });
        this.name = filter?.name ?? "";
        this.customerId = filter?.customerId;
        this.memberTypeId = filter?.memberTypeId;
        this.onlyCurrent = filter ? filter.onlyCurrent : true;
    }
}