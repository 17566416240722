import appConfig from "core/config";
import { isNullOrUndefined } from "util";

import { getLoginData } from "../storage/localStorage";

function buildRequest(body, customSegment = "") {
  const url = `${appConfig.baseApiUrl}account/${customSegment}`;
  const method = "POST";
  const headers = new Headers({ "Content-Type": "application/json" });

  return new Request(url, {
    method,
    headers,
    body
  });
}

function mapValidResponse(json) {
  const { memberId, firstName: name, email, token, refreshToken, tokenVersion, plan } = json;
  return {
    userData: { memberId, name, email },
    token,
    refreshToken,
    tokenVersion,
    plan
  };
}

function mapBadRequestResponse(json) {
  return json.error.message;
}

async function login(credentials) {
  const { email, password } = credentials;
  const body = JSON.stringify({ email, password });
  const response = await fetch(buildRequest(body));
  const json = await response.json();
  if (response.ok) return mapValidResponse(json);
  if (response.status === 400) throw mapBadRequestResponse(json);
  throw Error(response);
}

async function loginWithThirdParty(payload, thirdParty) {
  const body = JSON.stringify(payload);
  const response = await fetch(buildRequest(body, thirdParty));
  const json = await response.json();
  if (response.ok) return mapValidResponse(json);
  if (response.status === 400) throw mapBadRequestResponse(json);
  throw Error(response);
}

function isPasswordValid(str) {
  return str.length >= 8;
}

async function forgotPassword(payload) {
  const body = JSON.stringify(payload);
  const response = await fetch(buildRequest(body, "forgot"));
  if (response.ok) return null;
  if (response.status === 400) {
    const json = await response.json();
    throw mapBadRequestResponse(json);
  }
  console.log(response);
  throw Error(response);
}

async function resetPassword(payload) {
  const body = JSON.stringify(payload);
  const response = await fetch(buildRequest(body, "reset"));
  if (response.ok) return null;
  if (response.status === 400) {
    const json = await response.json();
    throw mapBadRequestResponse(json);
  }
  console.log(response);
  throw Error(response);
}

function getAuthHeaders() {
  return new Promise((resolve, reject) => {
    setTimeout(function () {
      const loginData = getLoginData();
      !isNullOrUndefined(loginData)
        ? resolve({
          Authorization: `Bearer ${loginData.token}`
        })
        : reject("Error");
    }, 2000);
  });
}

async function reauthenticate() {
  const oldLoginData = getLoginData();
  const { token, refreshToken } = oldLoginData;
  const body = JSON.stringify({ token, refreshToken });
  const response = await fetch(buildRequest(body, "refresh"));
  const json = await response.json();
  if (response.ok) {
    const newLoginData = mapValidResponse(json);
    if (oldLoginData.tokenVersion !== newLoginData.tokenVersion) {
      // If token versions don't match we force the user to re-login.
      navigateToLogout();
      throw Error("Token version changed, please re-login");
    }
    else {
      return newLoginData;
    }
  };
  if (response.status === 401) {
    // If after reauthentication we still get 401 then the access is actually unauthorized.
    navigateToLogout();
    throw Error("Access is unauthorized");
  }
  throw Error(response);
}

function navigateToLogout() {
  document.location = "/logout";
}

export {
  login,
  loginWithThirdParty,
  isPasswordValid,
  forgotPassword,
  resetPassword,
  getAuthHeaders,
  reauthenticate,
  navigateToLogout
};
