import apiFetch from "core/fetch/apiFetch";
import MemberFilter from "./memberFilter";

const _baseEndPoint = "members";

interface MemberInput {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    profileImage: string | null;
    notes: string;
    memberTypeId: number | null;
    customerId: number | null;
    isActive: boolean;
}

interface MemberViewModel {
    id: number;
    name: string;
    notes: string;
    memberType: {
        id: number;
        name: string;
    };
    isActive: boolean;
    customerName: string;
}

interface MemberType {
    id: number;
    name: string;
}

export const ADMIN_MEMBER_TYPE_ID = 1;
export const CUSTOMER_MEMBER_TYPE_ID = 2;
export const PENDING_MEMBER_TYPE_ID = 4;

export default class Member {
    static getDefaultInput(): MemberInput {
        return ({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            profileImage: null,
            notes: "",
            memberTypeId: null,
            customerId: null,
            isActive: true
        });
    }
    static async update(memberId: number, input: MemberInput): Promise<MemberViewModel> {
        return apiFetch(`${_baseEndPoint}/${memberId}`, "PUT", input);
    }
    static async search(filter = new MemberFilter(), page: number): Promise<MemberViewModel[]> {
        filter.setPaginationParameters(page);
        return await apiFetch(`${_baseEndPoint}?${filter.toQueryString()}`);
    }
    static async getMemberTypes(): Promise<MemberType[]> {
        return await apiFetch(`${_baseEndPoint}/memberTypes`);
    }
    static async getById(id: number) {
        return await apiFetch(`${_baseEndPoint}/${id}`);
    }
    static create(item: MemberInput): Promise<MemberViewModel> {
        return apiFetch(_baseEndPoint, "POST", item);
    }
    static async sendTestEmail() {
        await apiFetch("test/email", "POST");
    }
}
