import CustomerFilter from "entities/customerFilter";
import MemberFilter from "entities/memberFilter";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Input } from "reactstrap";
import { FilterForm, FilterInputRow, FilterSearchRow } from "core/components/TableActions";
import Toggle from "react-toggle";
import Dropdown from "core/components/FormDropdown";
import { AsyncDropdown } from "core/components/Dropdown";
import Customer from "entities/customer";

export default class MemberFilterComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCustomer: props.initialCustomer
        };
    }

    static propTypes = {
        filter: PropTypes.instanceOf(MemberFilter),
        onChange: PropTypes.func.isRequired,
        onFilter: PropTypes.func.isRequired,
        initialCustomer: PropTypes.shape({
            id: PropTypes.any,
            label: PropTypes.string
        })
    };

    updateFilter = filter => this.props.onChange({ ...this.props.filter, ...filter });

    render() {
        return (
            <FilterForm onSearch={() => this.props.onFilter()}>
                <FilterInputRow label="Nombre">
                    <Input
                        placeholder="Buscá por nombre o apellido"
                        value={this.props.filter.name}
                        onChange={e => this.updateFilter({ name: e.target.value })}
                    />
                </FilterInputRow>
                <FilterInputRow label="Cliente">
                    <AsyncDropdown
                        fetchItems={async legalName => Customer.search(new CustomerFilter({ legalName }))}
                        mapItemsToDropdownItems={items => items.map(x => ({ id: x.id, label: x.legalName }))}
                        placeholder="Seleccioná un cliente..."
                        selected={this.state.selectedCustomer}
                        onChange={customer => {
                            this.updateFilter({ customerId: customer && customer.id });
                            this.setState({ selectedCustomer: customer });
                        }}
                        prepopulateFromStorage={true}
                        isClearable={true} />
                </FilterInputRow>
                <FilterInputRow label="Tipo">
                    <Dropdown
                        placeholder="Seleccioná un tipo..."
                        items={[
                            { id: 1, label: "Administrador" },
                            { id: 2, label: "Cliente" },
                            { id: 3, label: "Invitado" }
                        ]}
                        selectedId={this.props.filter.memberTypeId}
                        onChange={memberTypeId => this.updateFilter({ memberTypeId })}
                        isClearable={true}
                    />
                </FilterInputRow>
                <FilterInputRow label="Sólo miembros vigentes?">
                    <Toggle
                        className="align-middle mb-2"
                        checked={this.props.filter.onlyCurrent}
                        onChange={e => this.updateFilter({ onlyCurrent: e.target.checked })}
                    />
                </FilterInputRow>
                <FilterSearchRow />
            </FilterForm>
        );
    }
}
