import PropTypes from "prop-types";
import React from "react";
import { FormGroup, Label } from "reactstrap";

const FormReadonly = ({ label, value }) => {
  return (
    <FormGroup className="form-readonly">
      <Label className="form-label">{label}</Label>
      <div className="mt-2">{value}</div>
    </FormGroup>
  );
};

FormReadonly.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired
};

export default FormReadonly;
