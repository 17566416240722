import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "core/components/Button";
import Statement from "entities/statement";
import { SimpleModal } from "core/components/modal/SimpleModal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import { Fragment } from "react";
import { base64toBlob, downloadWithFallback } from "core/util/fileUtilities";
import { Menu, Item, useContextMenu } from "react-contexify";
import LoadingOverlay from "react-loading-overlay";

LoadingOverlay.propTypes = undefined;

const StatementData = ({ id, statementLabel, setHtml }) => {
    const [loading, setIsLoading] = useState(false);
    const { show } = useContextMenu({ id });
    function handleContextMenu(event) {
        event.preventDefault();
        show(event);
    }
    return (
        <li key={id} className="mb-1" style={{ clear: "both", listStyle: "none" }}>
            <LoadingOverlay
                active={loading}
                spinner
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: "none"
                    })
                }}
            >
                <span id={id}>{statementLabel}</span>
                <div className="pull-right ml-2" onClick={handleContextMenu}>
                    <i className="fa fa-ellipsis-v wrapped" />
                </div>
                <Menu id={id} theme="dark">
                    <Item onClick={async () => {
                        setIsLoading(true);
                        const blob = await Statement.getHtml(id);
                        setHtml(await blob.text());
                        setIsLoading(false);
                    }}>
                        Ver comprobante
                    </Item>
                    <Item onClick={async () => {
                        setIsLoading(true);
                        const json = await Statement.getPdf(id);
                        const blob = base64toBlob(json.base64String);
                        downloadWithFallback(blob, `${statementLabel}.pdf`);
                        setIsLoading(false);
                    }}>
                        Exportar comprobante
                    </Item>
                </Menu>
            </LoadingOverlay>
        </li>
    );
};

const ServiceStatements = ({ statements }) => {
    const [html, setHtml] = useState(null);
    return (
        <Fragment>
            <ul style={{ paddingLeft: 0 }}>
                {statements.map((x) => (
                    <StatementData key={x.id} id={x.id} statementLabel={Statement.getLabel(x)} setHtml={setHtml} />
                ))}
            </ul>
            <SimpleModal show={!!html} size="lg">
                <ModalBody dangerouslySetInnerHTML={{ __html: html }} />
                <ModalFooter>
                    <Button className="btn btn-secondary" onClick={() => setHtml(null)} label="Cerrar" />
                </ModalFooter>
            </SimpleModal>
        </Fragment>
    );
};

ServiceStatements.propTypes = {
    statements: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default ServiceStatements;
