import apiFetch from "core/fetch/apiFetch";
import CustomerFilter from "./customerFilter";

const _baseEndPoint = "customers";

export interface CustomerInput {
    customerTypeId: number | null;
    legalName: string;
    address: string;
    documentNumber: number | null;
    taxResponsibilityTypeId: number | null;
    contactEmail: string;
    contactName: string;
    contractPrice: number | null;
    contractCreditHours: number | null;
    isActive: boolean;
}

interface CustomerAndMemberInput {
    customerTypeId: number | null;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    address: string;
    documentNumber: number | null;
    taxResponsibilityTypeId: number | null;
    memberTypeId: number | null;
}

interface CustomerOutput {
    id: number;
    legalName: string;
}

interface CustomerViewModel {
    id: number;
    legalName: string;
    address: string;
    documentNumber: number;
    contactEmail: string;
    contactName: string;
    taxResponsibilityTypeId: number;
    contractPrice?: number;
    contractCreditHours?: number;
    customerType: CustomerType;
    isActive: boolean;
}

interface DropdownOption {
    id: number;
    label: string;
}

export interface TaxResponsibilityType {
    id: number;
    description: string;
}

export interface CustomerType {
    id: number;
    name: string;
    isRecurrent: boolean;
}

export const GROUP_CUSTOMER_TYPE_ID = 3;
export const OCCASIONAL_CUSTOMER_TYPE_ID = 4;

export default class Customer {
    static getDefaultInput(): CustomerInput {
        return ({
            customerTypeId: null,
            legalName: "",
            address: "",
            documentNumber: null,
            taxResponsibilityTypeId: null,
            contactEmail: "",
            contactName: "",
            contractPrice: null,
            contractCreditHours: null,
            isActive: true
        });
    }
    static getDefaultInputWithMember(): CustomerAndMemberInput {
        return ({
            customerTypeId: null,
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            address: "",
            documentNumber: null,
            taxResponsibilityTypeId: null,
            memberTypeId: null
        });
    }
    static async update(memberId: number, input: CustomerInput): Promise<CustomerOutput> {
        return apiFetch(`${_baseEndPoint}/${memberId}`, "PUT", input);
    }
    static async search(filter = new CustomerFilter(), page: number): Promise<CustomerViewModel[]> {
        filter.setPaginationParameters(page);
        return await apiFetch(`${_baseEndPoint}?${filter.toQueryString()}`);
    }
    static async getCustomerTypes(): Promise<CustomerType[]> {
        return await apiFetch(`${_baseEndPoint}/customerTypes`);
    }
    static async getTaxResponsibilityTypes(): Promise<TaxResponsibilityType[]> {
        return await apiFetch(`${_baseEndPoint}/taxResponsibilityTypes`);
    }
    static async getById(id: number): Promise<CustomerInput> {
        return await apiFetch(`${_baseEndPoint}/${id}`);
    }
    static async getDropdownOptionById(id: number): Promise<DropdownOption> {
        const entity = await apiFetch(`${_baseEndPoint}/${id}`);
        return {
            id,
            label: entity.legalName
        };
    }
    static create(item: CustomerInput): Promise<CustomerOutput> {
        return apiFetch(_baseEndPoint, "POST", item);
    }
    static createWithMember(item: CustomerAndMemberInput): Promise<CustomerOutput> {
        return apiFetch(`${_baseEndPoint}/withMember`, "POST", item);
    }
}
