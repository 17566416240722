import ListViewWrapper from "core/components/ListViewWrapper";
import FilterComponent from "components/members/MemberFilterComponent";
import Table from "components/members/MemberTable";
import MemberFilter from "entities/memberFilter";
import Member from "entities/member";
import Customer from "entities/customer";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { CreateButton, TableActions } from "core/components/TableActions";
import pagination from "core/pagination";
import Spinner from "core/components/Spinner";
import { getGenericData, saveGenericData } from "core/storage/localStorage";

const filterStorageKey = "members-list-filters";

class MembersListPage extends Component {
    constructor() {
        super();
        this.search = this.search.bind(this);
        this.handleBottomScroll = this.handleBottomScroll.bind(this);
        this.state = {
            items: [],
            filter: this.initializeFilter(),
            loading: true,
            hasLoadedAllPages: false,
            page: 0,
            initialCustomer: null,
            filterLoading: true
        };
    }

    initializeFilter() {
        const saved = getGenericData(filterStorageKey);
        return saved ? new MemberFilter(saved) : new MemberFilter();
    }

    search() {
        saveGenericData(filterStorageKey, this.state.filter);
        this.loadPage(0);
    }

    handleBottomScroll() {
        if (!this.state.hasLoadedAllPages) {
            this.loadPage(this.state.page + 1);
        }
    }

    loadPage(page) {
        if (!this.state.loading) {
            const state = { loading: true, page };
            if (page === 0) { state.items = []; }
            this.setState(state);
        }
        Member.search(this.state.filter, page).then(items =>
            this.setState({
                items: page === 0 ? items : this.state.items.concat(items),
                loading: false,
                hasLoadedAllPages: items.length < pagination.pageSize
            }));
    }

    async componentDidMount() {
        const { customerId } = this.state.filter;
        if (customerId) {
            this.setState({ initialCustomer: await Customer.getDropdownOptionById(customerId), filterLoading: false });
        }
        else {
            this.setState({ filterLoading: false });
        }
        this.search();
    }

    render() {
        return (
            <ListViewWrapper
                title="Miembros"
                buttons={[
                    (<CreateButton
                        key="1"
                        onCreate={() => this.props.history.push("/members/create")}
                        text="Nuevo miembro"
                    />)
                ]}
            >
                <TableActions>
                    {this.state.filterLoading && <Spinner show={true} />}
                    {!this.state.filterLoading &&
                        <FilterComponent
                            filter={this.state.filter}
                            onChange={x => this.setState({ filter: new MemberFilter(x) })}
                            onFilter={this.search}
                            initialCustomer={this.state.initialCustomer}
                        />}
                </TableActions>
                <Table
                    onBottomScroll={this.handleBottomScroll}
                    members={this.state.items}
                    loading={this.state.loading}
                />
            </ListViewWrapper>
        );
    }
}

export default withRouter(MembersListPage);
