import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isNullOrUndefined } from "util";
import { getLoginData } from "../storage/localStorage";

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        !isNullOrUndefined(getLoginData())
            ? <Component {...props} />
            : <Redirect to={{ pathname: "/login" }} />
    )} />
);