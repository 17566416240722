import CustomerFilter from "entities/customerFilter";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FilterForm, FilterInputRow, FilterSearchRow } from "core/components/TableActions";
import { AsyncDropdown } from "core/components/Dropdown";
import Customer from "entities/customer";
import ServiceFilter from "entities/serviceFilter";
import Toggle from "react-toggle";

const ServiceFilterComponent = ({ filter, onChange, onFilter, initialCustomer }) => {
    const [selectedCustomer, setSelectedCustomer] = useState(initialCustomer);
    const updateFilter = updated => onChange({ ...filter, ...updated });
    return (
        <FilterForm onSearch={() => onFilter()}>
            <FilterInputRow label="Cliente">
                <AsyncDropdown
                    fetchItems={async legalName => Customer.search(new CustomerFilter({ legalName }))}
                    mapItemsToDropdownItems={items => items.map(x => ({ id: x.id, label: x.legalName }))}
                    placeholder="Seleccioná un cliente..."
                    selected={selectedCustomer}
                    onChange={customer => {
                        updateFilter({ customerId: customer && customer.id });
                        setSelectedCustomer(customer);
                    }}
                    prepopulateFromStorage={true}
                    isClearable={true} />
            </FilterInputRow>
            <FilterInputRow label="Sólo servicios impagos?">
                <Toggle
                    className="align-middle mb-2"
                    checked={filter.onlyUnpaid}
                    onChange={e => updateFilter({ onlyUnpaid: e.target.checked })}
                />
            </FilterInputRow>
            <FilterSearchRow />
        </FilterForm>
    );
};

ServiceFilterComponent.propTypes = {
    filter: PropTypes.instanceOf(ServiceFilter),
    onChange: PropTypes.func.isRequired,
    onFilter: PropTypes.func.isRequired,
    initialCustomer: PropTypes.shape({
        id: PropTypes.any,
        label: PropTypes.string
    })
};

export default ServiceFilterComponent;