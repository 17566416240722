import Filter from "core/filtering/filter";

export default class CustomerFilter extends Filter {
    legalName: string;
    onlyCurrent: boolean;
    onlyMonthly: boolean;
    constructor(filter?: { legalName: string; onlyCurrent: boolean; onlyMonthly: boolean; }) {
        super();
        this.legalName = filter?.legalName ?? "";
        this.onlyCurrent = filter?.onlyCurrent ?? true;
        this.onlyMonthly = filter?.onlyMonthly ?? false;
    }
}
