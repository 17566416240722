import { useState } from "react";
import CardWrapper, { wrapperWidth } from "core/components/CardWrapper";
import Actions from "core/components/FormDefaultActions";
import FormInput from "core/components/FormInput";
import FormToggle from "core/components/FormToggle";
import Dropdown, { AsyncFormDropdown } from "core/components/FormDropdown";
import { ADMIN_MEMBER_TYPE_ID, CUSTOMER_MEMBER_TYPE_ID, PENDING_MEMBER_TYPE_ID } from "entities/member";
import { Form } from "reactstrap";
import Spinner from "core/components/Spinner";
import CustomerFilter from "entities/customerFilter";
import Customer from "entities/customer";
import { toast } from "react-toastify";
import FormReadonly from "core/components/FormReadonly";

export const acceptedImageTypes = [
    "image/gif",
    "image/heic",
    "image/jpeg",
    "image/jpg",
    "image/png"
];

const MemberData = ({ member, initialCustomer, onUpdate, memberTypes }) => {
    const [selectedCustomer, setSelectedCustomer] = useState(
        initialCustomer && {
            id: initialCustomer.id,
            label: initialCustomer.legalName
        });
    const updateMember = newValue =>
        onUpdate({
            ...member,
            ...newValue
        });
    const showMemberTypeInput = !member.memberTypeId || member.memberTypeId !== ADMIN_MEMBER_TYPE_ID;
    return (
        <div>
            <div>
                <FormInput
                    id="firstName"
                    label="Nombre"
                    value={member.firstName}
                    onChange={e =>
                        updateMember({
                            firstName: e.target.value
                        })
                    }
                    mandatory
                />
            </div>
            <div>
                <FormInput
                    id="lastName"
                    label="Apellido"
                    value={member.lastName}
                    onChange={e =>
                        updateMember({
                            lastName: e.target.value
                        })
                    }
                    mandatory
                />
            </div>
            <div>
                <FormInput
                    id="email"
                    label="Email"
                    value={member.email}
                    onChange={e =>
                        updateMember({
                            email: e.target.value
                        })
                    }
                    mandatory
                />
            </div>
            <div>
                <FormInput
                    id="phoneNumber"
                    label="Número de teléfono"
                    value={member.phoneNumber || ""}
                    onChange={e =>
                        updateMember({
                            phoneNumber: e.target.value
                        })
                    }
                    mandatory={member.memberTypeId === CUSTOMER_MEMBER_TYPE_ID}
                    mandatoryReason="Requerido para miembros de tipo cliente"
                />
            </div>
            <div>
                <FormInput
                    id="notes"
                    label="Notas"
                    type="textarea"
                    rows={2}
                    value={member.notes || ""}
                    onChange={e =>
                        updateMember({
                            notes: e.target.value
                        })
                    }
                />
            </div>
            <div>
                <div className="form-group">
                    <label className="form-label">Imagen de perfil</label>
                    <br />
                    {member.profileImage &&
                        <img
                            src={member.profileImage}
                            alt="Imagen de perfil"
                            style={{ maxWidth: "100%", marginBottom: "10px" }}
                        />}
                    <input
                        type="file"
                        placeholder="Imagen de perfil"
                        onChange={evt => {
                            if (evt.target.files) {
                                const imageFile = evt.target.files[0];
                                if (acceptedImageTypes.includes(imageFile.type)) {
                                    var fileReader = new FileReader();
                                    fileReader.onloadend = function (readerEvt) {
                                        if (fileReader.readyState === FileReader.DONE) {
                                            updateMember({
                                                profileImage: readerEvt.target.result
                                            });
                                        }
                                    };
                                    fileReader.readAsDataURL(imageFile);
                                }
                                else {
                                    toast.error(`Tipo de archivo ${imageFile.type} no soportado`, "", 5 * 1000);
                                }
                            }
                        }}
                    />
                </div>
            </div>
            <div>
                {showMemberTypeInput &&
                    <Dropdown
                        placeholder="Seleccioná un tipo..."
                        label="Tipo de Membresía"
                        items={
                            memberTypes
                                .filter(x => x.id !== PENDING_MEMBER_TYPE_ID)
                                .map(x => ({ id: x.id, label: x.name }))
                        }
                        selectedId={member.memberTypeId}
                        onChange={memberTypeId => updateMember({ memberTypeId })}
                        mandatory
                    />}
                {!showMemberTypeInput &&
                    <FormReadonly
                        label="Tipo de Membresía"
                        value={memberTypes.find(x => x.id === member.memberTypeId).name}
                    />}
            </div>
            <div>
                <AsyncFormDropdown
                    label="Cliente"
                    fetchItems={async legalName => Customer.search(new CustomerFilter({ legalName }))}
                    mapItemsToDropdownItems={items => items.map(x => ({ id: x.id, label: x.legalName }))}
                    placeholder="Seleccioná un cliente..."
                    selected={selectedCustomer}
                    onChange={customer => {
                        updateMember({ customerId: customer && customer.id });
                        setSelectedCustomer(customer);
                    }}
                    prepopulateFromStorage={true}
                    isClearable={true} />
            </div>
            <div>
                <FormToggle
                    label="Vigente?"
                    checked={member.isActive}
                    onChange={e =>
                        updateMember({
                            isActive: e
                        })
                    }
                />
            </div>
        </div>
    );
};

const MemberForm = ({ member, initialCustomer, onSubmit, onCancel, onMemberUpdated, submitting, memberTypes, isEdit, loading }) => {
    const handleSubmit = e => {
        e.preventDefault();
        onSubmit();
    };

    const title = isEdit ? "Editar miembro" : "Crear miembro";

    return (
        <CardWrapper title={title} width={wrapperWidth.s}>
            <Spinner show={loading} size="fa-3x" />
            {!loading &&
                <Form onSubmit={handleSubmit}>
                    <MemberData
                        member={member}
                        initialCustomer={initialCustomer}
                        onUpdate={onMemberUpdated}
                        memberTypes={memberTypes}
                    />
                    <Actions
                        onSubmit={handleSubmit}
                        onCancel={onCancel}
                        submitting={submitting}
                    />
                </Form>}
        </CardWrapper>
    );
};

export default MemberForm;
