function checkVersionAndReload() {
    fetch("/clientVersion.json?v=" + (new Date().getTime())) // adding the querystring forces us to jump the cache
        .then(res => res.json())
        .then(data => {
            if (process.env.REACT_APP_CLIENT_VERSION !== data.ClientVersion) {
                console.log("reloaded due to new version '" + data.ClientVersion + "' found on the server");
                window.location.reload();
            }
        });
}
export default function setupVersionAutoReload() {
    setInterval(() => {
        checkVersionAndReload();
    }, 60 * 60 * 1000);
}
