import React from "react";

export const PricesPage = () => {
    return (
        <div className="container-fluid container-fluid-spacious">
            <div className="container pt-3">
                <div className="row card-deck">
                    <div className="card mb-4 box-shadow p-3">
                        <h2>Planes de membresías mensuales</h2>
                        <table width={250} cellPadding={4}>
                            <tr><td>Móvil</td><td>$36.000</td></tr>
                            <tr><td>Fijo</td><td>$48.000</td></tr>
                        </table>
                    </div>
                    <div className="card mb-4 box-shadow p-3">
                        <h2>Otros</h2>
                        <p>Sala de reuniones por hora: $4000</p>
                        <p>
                            <strong>No estamos tomando membresías diarias por el momento</strong> (sí ofrecemos que los interesados se queden a trabajar un día gratis para que conozcan el espacio)
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}; 