import CardWrapper, { wrapperWidth } from "core/components/CardWrapper";
import Actions from "core/components/FormDefaultActions";
import FormInput from "core/components/FormInput";
import { Form } from "reactstrap";
import Spinner from "core/components/Spinner";
import Dropdown from "core/components/FormDropdown";
import CustomDatePicker from "core/components/FormDatePicker";
import moment from "moment";

const ServiceData = ({ service, onUpdate, customers }) => {
    const updateService = newValue =>
        onUpdate({
            ...service,
            ...newValue
        });
    return (
        <div>
            <div>
                <Dropdown
                    placeholder="Seleccioná un cliente..."
                    label="Cliente"
                    items={customers.map(x => ({ id: x.id, label: x.legalName }))}
                    selectedId={service.customerId}
                    onChange={customerId => updateService({ customerId })}
                />
            </div>
            <div>
                <CustomDatePicker
                    label="Fecha comienzo"
                    selected={service.startDate ? moment.utc(service.startDate).toDate() : null}
                    onChange={startDate => {
                        if (startDate) { updateService({ startDate }); }
                        else { updateService({ startDate: null }); }
                    }}
                    showTimeSelect={false}
                    dateFormat={"MMMM d, yyyy"}
                    mandatory
                />
            </div>
            <div>
                <CustomDatePicker
                    label="Fecha fin"
                    selected={service.endDate ? moment.utc(service.endDate).toDate() : null}
                    onChange={endDate => {
                        if (endDate) { updateService({ endDate }); }
                        else { updateService({ endDate: null }); }
                    }}
                    showTimeSelect={false}
                    dateFormat={"MMMM d, yyyy"}
                    mandatory
                />
            </div>
            <div>
                <FormInput
                    id="amount"
                    label="Monto (en pesos sin decimales)"
                    value={service.amount}
                    onChange={e => updateService({ amount: parseInt(e.target.value || 0) })}
                    mandatory
                    type="number"
                    min="0"
                    step="50"
                />
            </div>
            <div>
                <FormInput
                    id="description"
                    label="Concepto"
                    value={service.description || ""}
                    onChange={e => updateService({ description: e.target.value })}
                    mandatory
                />
            </div>
        </div>
    );
};

const ServiceForm = ({ service, onSubmit, onCancel, onDelete, onServiceUpdated, submitting, customers, isEdit, loading }) => {
    const handleSubmit = e => {
        e.preventDefault();
        onSubmit();
    };

    const title = isEdit ? "Editar servicio" : "Crear servicio";

    return (
        <CardWrapper title={title} width={wrapperWidth.s}>
            <Spinner show={loading} size="fa-3x" />
            {!loading &&
                <Form onSubmit={handleSubmit}>
                    <ServiceData
                        service={service}
                        onUpdate={onServiceUpdated}
                        customers={customers} />
                    <Actions
                        onSubmit={handleSubmit}
                        onCancel={onCancel}
                        submitting={submitting}
                        onDelete={onDelete}
                        deleteEnabled={isEdit}
                        deleteOptions={isEdit ? {
                            entityName: "el servicio",
                            instanceName: service.description
                        } : null}

                    />
                </Form>}
        </CardWrapper>
    );
};

export default ServiceForm;
