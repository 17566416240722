import MemberForm from "components/members/MemberForm";
import Member from "entities/member";
import React from "react";
import { withRouter } from "react-router-dom";
import makeValidable from "core/components/Validable";
import Customer from "entities/customer";

const ValidableMemberForm = makeValidable(MemberForm);

class MemberEditPage extends React.Component {
    constructor(props) {
        super(props);
        this.validableRef = React.createRef();
        this.state = {
            member: Member.getDefaultInput(),
            memberTypes: [],
            submitting: false,
            showValidations: false,
            loading: true,
            initialCustomer: null
        };

        this.navigateBack = this.navigateBack.bind(this);
    }

    memberId = () => {
        return this.props.match.params.id;
    };

    async componentDidMount() {
        const state = {
            memberTypes: await Member.getMemberTypes(),
            loading: false
        };
        const id = this.memberId();
        if (id) {
            state.member = await Member.getById(id);
            if (state.member.customerId) {
                state.initialCustomer = await Customer.getById(state.member.customerId);
            }
        }
        this.setState(state);
    }

    componentDidUpdate(prevProps) {
        if (!this.memberId() && prevProps.match.params.id) {
            this.setState({ member: Member.getDefaultInput() });
        }
    }

    save = () => {
        this.setState({ showValidations: true }, async () => {
            if (this.validableRef.current.isValid()) {
                this.setState({ submitting: true });
                const id = this.memberId();
                const savePromise = id
                    ? Member.update(id, this.state.member)
                    : Member.create(this.state.member);
                savePromise
                    .then(() => this.props.history.push("/members"))
                    .catch(() => this.setState({ submitting: false }));
            };
        });
    };

    navigateBack = () => {
        this.props.history.push("/members");
    };

    render() {
        const { showValidations, member, submitting, memberTypes, loading, initialCustomer } = this.state;

        return (
            <ValidableMemberForm
                ref={this.validableRef}
                showValidations={showValidations}
                member={member}
                initialCustomer={initialCustomer}
                onSubmit={this.save}
                onCancel={this.navigateBack}
                onMemberUpdated={member => this.setState({ member })}
                submitting={submitting}
                memberTypes={memberTypes}
                isEdit={!!this.memberId()}
                loading={loading}
            />
        );
    }
}

export default withRouter(MemberEditPage);
