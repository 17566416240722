import apiFetch from "core/fetch/apiFetch";
import Filter from "core/filtering/filter";

const _baseEndPoint = "bookingResources";

interface BookingResourceViewModel {
    id: number;
    name: string;
}

export default class BookingResource {
    static async search(filter = new Filter(), page: number): Promise<BookingResourceViewModel[]> {
        filter.setPaginationParameters(page);
        return await apiFetch(`${_baseEndPoint}?${filter.toQueryString()}`);
    }
}
