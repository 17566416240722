import React, { useState, useEffect } from "react";
import CardWrapper, { wrapperWidth } from "core/components/CardWrapper";
import Actions from "core/components/FormDefaultActions";
import FormInput from "core/components/FormInput";
import FormToggle from "core/components/FormToggle";
import Dropdown from "core/components/FormDropdown";
import { Form } from "reactstrap";
import Spinner from "core/components/Spinner";
import { CustomerInput, CustomerType, TaxResponsibilityType } from "entities/customer";

interface DataProps {
    customer: CustomerInput;
    onUpdate: (newValue: CustomerInput) => void;
    taxResponsibilityTypes: TaxResponsibilityType[];
    customerTypes: CustomerType[];
}

const CustomerData = ({ customer, onUpdate, taxResponsibilityTypes, customerTypes }: DataProps) => {
    const updateCustomer = (newValue: Partial<CustomerInput>) =>
        onUpdate({
            ...customer,
            ...newValue
        });
    const [hasCompanyContract, setHasCompanyContract] = useState(false);
    useEffect(() => {
        setHasCompanyContract(!!customer.contractPrice);
        //HACK: We only care about the initial value.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    <FormInput
                        id="legalName"
                        label="Razón Social"
                        value={customer.legalName}
                        onChange={e =>
                            updateCustomer({
                                legalName: e.target.value
                            })
                        }
                        mandatory
                    />
                </div>
                <div className="col-md-6">
                    <FormInput
                        id="address"
                        label="Domicilio"
                        value={customer.address}
                        onChange={e =>
                            updateCustomer({
                                address: e.target.value
                            })
                        }
                        mandatory
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <Dropdown
                        placeholder="Seleccioná un tipo de responsable..."
                        label="Tipo de Responsable AFIP"
                        items={taxResponsibilityTypes.map(x => ({ id: x.id, label: x.description }))}
                        selectedId={customer.taxResponsibilityTypeId}
                        onChange={taxResponsibilityTypeId => updateCustomer({ taxResponsibilityTypeId })}
                        mandatory
                    />
                </div>
                <div className="col-md-6">
                    <FormInput
                        id="documentNumber"
                        label="CUIT/DNI (sin guiones ni puntos)"
                        value={customer.documentNumber || ""}
                        onChange={e =>
                            updateCustomer({
                                documentNumber: parseInt(e.target.value)
                            })
                        }
                        mandatory
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <FormInput
                        id="contactEmail"
                        label="Email del Contacto"
                        value={customer.contactEmail}
                        onChange={e =>
                            updateCustomer({
                                contactEmail: e.target.value
                            })
                        }
                        mandatory
                    />
                </div>
                <div className="col-md-6">
                    <FormInput
                        id="contactName"
                        label="Nombre del Contacto (para usar en el email mensual)"
                        value={customer.contactName}
                        onChange={e =>
                            updateCustomer({
                                contactName: e.target.value
                            })
                        }
                        mandatory
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <FormToggle
                        label="Contrato por empresa?"
                        checked={hasCompanyContract}
                        onChange={e => {
                            setHasCompanyContract(e);
                            if (!e) {
                                updateCustomer({
                                    contractPrice: null,
                                    contractCreditHours: null
                                });
                            }
                        }}
                    />
                    {hasCompanyContract &&
                        <>
                            <br />
                            <FormInput
                                id="contractPrice"
                                label="Monto Contrato (en pesos sin decimales)"
                                value={customer.contractPrice}
                                onChange={e => updateCustomer({ contractPrice: parseInt(e.target.value) || null })}
                                type="number"
                                min="0"
                                step="50"
                                mandatory
                            />
                            <FormInput
                                label="Crédito de reservas Contrato (en horas, por mes)"
                                value={customer.contractCreditHours}
                                onChange={e => updateCustomer({ contractCreditHours: parseInt(e.target.value) || null })}
                                type="number"
                                mandatory
                            />
                        </>}
                </div>
                <div className="col-md-6">
                    <Dropdown
                        placeholder="Seleccioná un tipo..."
                        label="Tipo de Cliente"
                        items={
                            customerTypes
                                .map(x => ({ id: x.id, label: x.name }))
                        }
                        selectedId={customer.customerTypeId}
                        onChange={customerTypeId => updateCustomer({ customerTypeId })}
                        mandatory
                    />
                </div>
            </div>
        </div>
    );
};

interface FromProps {
    customer: CustomerInput;
    onSubmit: () => void;
    onCancel: () => void;
    onCustomerUpdated: (customer: CustomerInput) => void;
    submitting: boolean;
    taxResponsibilityTypes: TaxResponsibilityType[];
    customerTypes: CustomerType[];
    isEdit: boolean;
    loading: boolean;
}

const CustomerForm = ({ customer, onSubmit, onCancel, onCustomerUpdated, submitting, taxResponsibilityTypes, customerTypes, isEdit, loading }: FromProps) => {
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit();
    };

    const title = isEdit ? "Editar cliente" : "Crear cliente";

    return (
        <CardWrapper title={title} width={wrapperWidth.m}>
            <Spinner show={loading} size="fa-3x" />
            {!loading &&
                <Form onSubmit={handleSubmit}>
                    <CustomerData
                        customer={customer}
                        onUpdate={onCustomerUpdated}
                        taxResponsibilityTypes={taxResponsibilityTypes}
                        customerTypes={customerTypes}
                    />
                    <Actions
                        onSubmit={handleSubmit}
                        onCancel={onCancel}
                        submitting={submitting}
                    />
                </Form>}
        </CardWrapper>
    );
};

export default CustomerForm;
