import "./FormCheckBox.scss";

import PropTypes from "prop-types";
import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

const FormCheckBox = ({
  id,
  label,
  checkboxLabel,
  className,
  checked,
  onChange,
  disabled
}) => {
  return (
    <FormGroup className={className}>
      {label ? <Label className="form-label">{label}</Label> : null}
      <div>
        <Label check>
          <Input
            type="checkbox"
            id={id}
            checked={checked}
            disabled={disabled}
            onChange={e => onChange(e.target.checked)}
          />
          {checkboxLabel}
        </Label>
      </div>
    </FormGroup>
  );
};

FormCheckBox.defaultProps = {
  checked: false
};

FormCheckBox.propTypes = {
  id: PropTypes.node,
  label: PropTypes.string,
  checkboxLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default FormCheckBox;
