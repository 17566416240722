import apiFetch from "core/fetch/apiFetch";
import BookingFilter from "./bookingFilter";

const _baseEndPoint = "bookings";

interface BookingInput {
    bookingResourceId: number | null;
    startDate: Date | null;
    duration: number;
    notes: string | null;
    memberForId: number | null;
}

interface BookingSearchResult {
    id: number;
    bookingResource: {
        id: number;
        name: string;
    };
    member: {
        id: number;
        name: string;
    };
    startDate: Date;
    endDate: Date;
    duration: number;
    notes: string;
}

interface BookingViewModel {
    id: number;
    bookingResourceId: number;
    startDate: Date;
    duration: number;
}

export default class Booking {
    static getDefaultInput(): BookingInput {
        return ({
            bookingResourceId: null,
            startDate: null,
            duration: 30,
            notes: null,
            memberForId: null
        });
    }
    static async update(bookingId: number, input: BookingInput) {
        return apiFetch(`${_baseEndPoint}/${bookingId}`, "PUT", input);
    }
    static async search(filter = new BookingFilter(), page: number): Promise<BookingSearchResult[]> {
        filter.setPaginationParameters(page);
        return await apiFetch(`${_baseEndPoint}?${filter.toQueryString()}`);
    }
    static async getById(id: number) {
        return await apiFetch(`${_baseEndPoint}/${id}`);
    }
    static create(item: BookingInput): Promise<BookingViewModel> {
        return apiFetch(_baseEndPoint, "POST", item);
    }
    static async delete(id: number) {
        apiFetch(`${_baseEndPoint}/${id}`, "DELETE");
    }
    static async getAvailableMinutes(): Promise<number> {
        return await apiFetch(`${_baseEndPoint}/availableminutes`);
    }
}
