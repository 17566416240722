import ListViewRowWrapper from "core/components/ListViewRowWrapper";
import Row from "components/customers/CustomerTableRow";
import PropTypes from "prop-types";
import React from "react";

const Table = ({ customers, loading, onBottomScroll }) => {
    const rows = customers.map(x => <Row customer={x} key={x.id} />);
    const columns = ["Nombre", "Email", "CUIT/DNI", "Tipo", "Recurrente?", "Vigente?"];
    return (
        <table className="table table-hover">
            <thead><tr>{columns.map((x, idx) => <th key={idx}>{x}</th>)}</tr></thead>
            <tbody>
                <ListViewRowWrapper
                    rows={rows}
                    numberOfColumns={columns.length}
                    loading={loading}
                    onBottomScroll={onBottomScroll}
                />
            </tbody>
        </table>
    );
};

Table.propTypes = {
    customers: PropTypes.arrayOf(PropTypes.any).isRequired,
    loading: PropTypes.bool.isRequired,
    onBottomScroll: PropTypes.func.isRequired
};

export default Table;
