import { Fragment } from "react";

interface ButtonProps {
  type?: "button" | "submit" | "reset";
  label: string;
  onClick?: () => void;
  className: string;
  iconName?: string;
  showSpinner?: boolean;
  disabled?: boolean;
}

const Button = ({
  type = "button",
  label,
  onClick,
  className,
  iconName,
  showSpinner,
  disabled
}: ButtonProps) => {

  const handleClick = () => {
    if (type === "submit")
      return;
    else if (onClick)
      onClick();
  };

  return (
    <button
      type={type}
      className={`btn ${className}`}
      onClick={handleClick}
      disabled={disabled || showSpinner}
    >
      {label}
      {showSpinner && (
        <Fragment>
          <span>
            &nbsp;
            <i className="fa fa-spin fa-spinner" />
          </span>
        </Fragment>
      )}
      {iconName && !showSpinner && (
        <Fragment>
          &nbsp;
          <span className={`icon icon-${iconName}`} />
        </Fragment>
      )}
    </button>
  );
};

export default Button;
