import "styles/app.scss";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "react-toggle/style.css";
import "react-contexify/dist/ReactContexify.css";

import React from "react";
import ReactDOM from "react-dom";
import App from "App";

import setupVersionAutoReload from "core/util/versionUtilities";
setupVersionAutoReload();

ReactDOM.render(<App />, document.querySelector("#root"));