import React from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";

export default class SaveConfirmationModal extends React.PureComponent {
  static propTypes = {
    entityName: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onShowUpdated: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    text: PropTypes.string.isRequired,
    submitting: PropTypes.bool
  };

  render() {
    const { entityName, show, onShowUpdated, onOk, onCancel, text, submitting } = this.props;
    return (
      <Modal
        title={`Guardar ${entityName}`}
        show={show}
        onShowUpdated={onShowUpdated}
        okTitle="Guardar"
        onOk={onOk}
        onCancel={onCancel}
        showCancel={true}
        submitting={submitting}
      >
        <p>{text}</p>
      </Modal>
    );
  }
}
