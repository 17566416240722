import React, { useState } from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { isCurrentMonth } from "core/util/dateUtilities";
import { ModalBody, ModalFooter, SimpleModal } from "core/components/modal/SimpleModal";
import Button from "core/components/Button";
import { FormInput } from "core/components";
import Service from "entities/service";

const ServiceAdjustments = ({ service }) => {
    const { id, startDate, amount } = service;
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const [adjustedAmount, setAdjustedAmount] = useState(amount);
    const [addedMinutes, setAddedMinutes] = useState(0);
    const canAdjust = isCurrentMonth(startDate);
    return (
        <>
            {canAdjust &&
                <Button
                    label="Ajustar"
                    type="button"
                    onClick={() => setShowModal(true)}
                    className="btn-primary mr-2"
                />}
            <SimpleModal show={showModal} size="sm">
                <ModalBody>
                    <FormInput
                        label="Nuevo monto"
                        value={adjustedAmount}
                        onChange={e => setAdjustedAmount(parseInt(e.target.value || 0))}
                        type="number"
                    />
                    <FormInput
                        label="Minutos a agregar"
                        value={addedMinutes}
                        onChange={e => setAddedMinutes(parseInt(e.target.value || 0))}
                        type="number"
                    />
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-secondary" onClick={() => setShowModal(false)} label="Cancelar" />
                    <Button
                        className="btn btn-primary"
                        onClick={async () => {
                            setSaving(true);
                            try {
                                await Service.adjust(id, { adjustedAmount, addedMinutes });
                                history.go(0);
                            }
                            catch {
                                setSaving(false);
                            }
                        }}
                        label="Guardar"
                        showSpinner={saving}
                    />
                </ModalFooter>
            </SimpleModal>
        </>
    );
};

ServiceAdjustments.propTypes = {
    service: PropTypes.any.isRequired
};

export default ServiceAdjustments;