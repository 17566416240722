import Filter from "core/filtering/filter";

export default class ServiceFilter extends Filter {
    customerId?: string;
    onlyUnpaid: boolean;
    constructor(filter?: { customerId?: string; onlyUnpaid: boolean }) {
        super({ $orderby: "StartDate desc" });
        this.customerId = filter?.customerId;
        this.onlyUnpaid = filter?.onlyUnpaid ?? false;
    }
}
