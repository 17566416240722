import { saveAs } from "file-saver";

export function base64toBlob(base64Data, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
}

export function downloadWithFallback(blob, fileName) {
    if (!navigator.userAgent.match("CriOS")) {
        saveAs(blob, fileName);
    } else {
        // For Chrome in iOS devices.
        var reader = new FileReader();
        reader.onloadend = () => {
            const a = document.createElement("a");
            a.href = reader.result;
            a.style.display = "none";
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.parentNode.removeChild(a);
        };
        reader.readAsDataURL(blob);
    }
}
