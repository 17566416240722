import React from "react";

const NotFound = ({ location }) => {
    return (
        <div className="m-4">
            Página no encontrada
            <br />
            Nombre de la ruta: {location.pathname}
        </div>
    );
};

export default NotFound;