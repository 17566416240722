import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BottomScrollListener from "core/components/BottomScrollListener";

const ListViewRowWrapper = ({ rows, numberOfColumns, loading, onBottomScroll }) => {
  const content = (
    <Fragment>
      {rows}
      {rows.length === 0 && !loading && <tr><td colSpan={numberOfColumns}><span>No se encontraron datos</span></td></tr>}
      {loading && <tr><td colSpan={numberOfColumns}><span>Cargando... <i className="fa fa-spin fa-spinner" /></span></td></tr>}
    </Fragment>
  );
  if (onBottomScroll !== undefined) {
    return (
      <BottomScrollListener onBottom={onBottomScroll}>{content}</BottomScrollListener>
    );
  }
  else {
    return content;
  }
};

ListViewRowWrapper.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.node).isRequired,
  numberOfColumns: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  onBottomScroll: PropTypes.func
};

export default ListViewRowWrapper;
