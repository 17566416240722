import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const Nav = ({ to, children, className, onClick }) => (
  <Link to={to} className={className} onClick={onClick}>
    {children}
  </Link>
);

const NavLink = ({ to, children, className, onClick }) => {
  var classes = classNames(className, {
    "nav-link": true
  });
  return (
    <Nav to={to} className={classes} onClick={onClick}>
      {children}
    </Nav>
  );
};

const NavBrand = ({ to, children, className }) => {
  var classes = classNames(className, {
    "nav-brand": true
  });
  return (
    <Nav to={to} className={classes}>
      {children}
    </Nav>
  );
};

const NavAvatar = ({ to, children, className }) => {
  var classes = classNames(className, {
    "navbar-btn-avatar": true
  });
  return (
    <Nav to={to} className={classes}>
      {children}
    </Nav>
  );
};

NavLink.propTypes = NavBrand.protoTypes = NavAvatar.protoTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired
};

export { NavLink, NavBrand, NavAvatar };
