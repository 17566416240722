import apiFetch from "core/fetch/apiFetch";

const _baseEndPoint = "statements";

export interface StatementViewModel {
    id: number;
    statementType: string;
    number?: number;
    relatedStatementNumber?: number;
    isInvoice: boolean;
}

export default class Statement {
    static async getHtml(id: number) {
        return await apiFetch(`${_baseEndPoint}/${id}/html`);
    }
    static async getPdf(id: number) {
        return await apiFetch(`${_baseEndPoint}/${id}/pdf`);
    }
    static async generateCreditNote(statementId: number) {
        return apiFetch(`${_baseEndPoint}/${statementId}/cancel`, "POST");
    }
    static async authorizeStatement(statementId: number) {
        return apiFetch(`${_baseEndPoint}/${statementId}`, "PUT");
    }
    static getLabel(statement: StatementViewModel) {
        const { statementType, number } = statement;
        return `${statementType.substring(0, statementType.length - 2)} ${number || "N/A"}`;
    }
}