import React from "react";

export const wrapperWidth = {
  s: "col-12 col-sm-8 col-md-6 mx-auto",
  m: "col-12 col-sm-8 col-md-10 mx-auto",
  l: "col-12 col-sm-8 col-md-12 mx-auto"
};

interface CardWrapperProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
  width?: string;
  bgColor?: string;
}

const CardWrapper: React.FC<CardWrapperProps> = ({ title, subtitle, children, width, bgColor }) => (
  <div className="container main-content" >
    <div className="row">
      <div className={width}>
        <div className="card" style={{ backgroundColor: bgColor }}>
          <div className="card-body">
            <div className="card-title">
              <h4 className="form-title">{title}</h4>
              {subtitle && <h4 className="form-title">{subtitle}</h4>}
            </div>
            <hr />
            {children}
          </div>
        </div>
      </div>
    </div>
  </div>
);

CardWrapper.defaultProps = {
  width: wrapperWidth.l
};

export default CardWrapper;
