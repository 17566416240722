import React from "react";
import PropTypes from "prop-types";
import { Modal as ReactstrapModal } from "reactstrap";

const SimpleModal = ({ children, show, ...props }) => (
  <ReactstrapModal
    isOpen={show}
    modalTransition={{ timeout: 100 }}
    backdropTransition={{ timeout: 200 }}
    {...props}
  >
    {children}
  </ReactstrapModal>
);

const ModalHeader = ({ title }) => (
  <div className="modal-header">
    <h3 className="mb-0">{title}</h3>
  </div>
);

const ModalBody = ({ children }) => (
  <div className="modal-body">{children}</div>
);

const ModalFooter = ({ children }) => (
  <div className="modal-footer">{children}</div>
);

SimpleModal.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

ModalBody.propTypes = {
  children: PropTypes.node.isRequired
};

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired
};

ModalFooter.propTypes = {
  children: PropTypes.node
};

export { SimpleModal, ModalHeader, ModalBody, ModalFooter };
