import { RetirementConfirmationModal, SaveConfirmationModal } from "core/components/modal";
import PropTypes from "prop-types";
import React from "react";
import { Button } from "reactstrap";

export default class FormDefaultActions extends React.Component {
  static defaultProps = {
    submitEnabled: true,
    deleteEnabled: false
  };

  static propTypes = {
    submitEnabled: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    deleteEnabled: PropTypes.bool,
    deleteOptions: PropTypes.shape({
      entityName: PropTypes.string.isRequired,
      instanceName: PropTypes.string.isRequired,
      actionName: PropTypes.string
    }),
    submitting: PropTypes.bool,
    saveOptions: PropTypes.shape({
      entityName: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    }),
    customButtonText: PropTypes.string,
    onCustomButtonClick: PropTypes.func
  };

  state = {
    showDeleteConfirmation: false,
    showSaveConfirmation: false
  };

  handleDeletion = () => {
    if (!this.props.deleteOptions) {
      this.props.onDelete();
    }
    else {
      this.setState({ showDeleteConfirmation: true });
    }
  };

  handleSave = (e) => {
    if (!this.props.saveOptions) {
      this.props.onSubmit(e);
    }
    else {
      e.preventDefault();
      this.setState({ showSaveConfirmation: true });
    }
  };

  render() {
    const {
      submitEnabled,
      submitting,
      onSubmit,
      onCancel,
      onDelete,
      deleteEnabled,
      deleteOptions,
      saveOptions,
      customButtonText,
      onCustomButtonClick
    } = this.props;
    const deleteLabel = deleteOptions && deleteOptions.actionName ? deleteOptions.actionName : "Eliminar";
    return (
      <div className="row justify-content-center p-2 mt-3">
        <Button
          type="button"
          color="secondary"
          onClick={onCancel}
          className="m-1"
        >
          Cancelar
        </Button>
        {submitEnabled &&
          <Button
            type="submit"
            color="primary"
            onClick={this.handleSave}
            className="m-1"
          >
            {submitting && !saveOptions
              ? <span><i className="fa fa-spin fa-spinner" /> Guardando</span>
              : <span>Guardar</span>}
          </Button>}
        {deleteEnabled &&
          <Button
            type="button"
            color="danger"
            onClick={this.handleDeletion}
            className="m-1"
          >
            {deleteLabel}
          </Button>}
        {customButtonText && onCustomButtonClick &&
          <Button
            type="button"
            color="primary"
            onClick={onCustomButtonClick}
            className="m-1 ml-3"
          >
            {customButtonText}
          </Button>}
        {deleteOptions &&
          <RetirementConfirmationModal
            entityName={deleteOptions.entityName}
            instanceName={deleteOptions.instanceName}
            actionName={deleteLabel}
            show={this.state.showDeleteConfirmation}
            onShowUpdated={showDeleteConfirmation => this.setState({ showDeleteConfirmation })}
            onOk={onDelete}
          />}
        {saveOptions &&
          <SaveConfirmationModal
            entityName={saveOptions.entityName}
            text={saveOptions.text}
            show={this.state.showSaveConfirmation}
            onShowUpdated={showSaveConfirmation => this.setState({ showSaveConfirmation })}
            onOk={onSubmit}
            submitting={submitting}
          />}
      </div>
    );
  }
}
