import { useState } from "react";
import MemberFilter from "entities/memberFilter";
import { FilterForm, FilterInputRow, FilterSearchRow } from "core/components/TableActions";
import { AsyncDropdown } from "core/components/Dropdown";
import Member from "entities/member";

const UnlockFilterComponent = ({ filter, onChange, onFilter, initialMember }) => {
    const [selectedMember, setSelectedMember] = useState(
        initialMember && {
            id: initialMember.id,
            label: initialMember.firstName + " " + initialMember.lastName
        });
    const updateFilter = updated => onChange({ ...filter, ...updated });
    return (
        <FilterForm onSearch={() => onFilter()}>
            <FilterInputRow label="Miembro">
                <AsyncDropdown
                    fetchItems={async name => Member.search(new MemberFilter({ name }))}
                    mapItemsToDropdownItems={items => items.map(x => ({ id: x.id, label: x.name }))}
                    placeholder="Seleccioná un miembro..."
                    selected={selectedMember}
                    onChange={member => {
                        updateFilter({ memberId: member && member.id });
                        setSelectedMember(member);
                    }}
                    prepopulateFromStorage={true}
                    isClearable={true} />
            </FilterInputRow>
            <FilterSearchRow />
        </FilterForm>
    );
};

export default UnlockFilterComponent;