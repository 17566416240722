import { forgotPassword } from "core/fetch/auth";
import makeValidable from "core/components/Validable";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { toast } from "react-toastify";

const ValidableForgotPasswordForm = makeValidable(ForgotPasswordForm);

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.validableRef = React.createRef();
    this.state = {
      email: "",
      submitting: false,
      showValidations: false,
      showSuccessMessage: false
    };
  }

  onSubmit = () => {
    this.setState({ showValidations: true }, async () => {
      if (this.validableRef.current.isValid()) {
        this.setState({ submitting: true });
        try {
          await forgotPassword({ email: this.state.email });
          toast.success("El email para cambio de contraseña fue enviado");
          this.setState({ showSuccessMessage: true });
        } catch (e) {
          const errorMessage = (e instanceof Error || e instanceof TypeError) ? "Ha ocurrido un error" : e;
          toast.error(errorMessage, "", 3 * 1000);
          this.setState({ submitting: false });
        }
      };
    });
  };

  render() {
    return (
      <ValidableForgotPasswordForm
        ref={this.validableRef}
        showValidations={this.state.showValidations}
        email={this.state.email}
        onSubmit={this.onSubmit}
        onEmailUpdated={email => this.setState({ email })}
        submitting={this.state.submitting}
        showSuccessMessage={this.state.showSuccessMessage}
      />
    );
  }
}

export default withRouter(ForgotPassword);
