import React from "react";
import Faq from "react-faq-component";

const data = {
  title: "Preguntas Frecuentes",
  rows: [
    {
      title: "¿Cómo funciona el sistema de contratación?",
      content: "Nuestras membresías son mensuales y los abonos se reciben la primer semana de cada mes. Por un tema de seguridad, tranquilidad y armonía en el espacio, no alquilamos posiciones ni por hora ni por día."
    },
    {
      title: "¿Cuáles son los medios de pago?",
      content: "Aceptamos efectivo, depósitos, transferencias y tarjetas de crédito."
    },
    {
      title: "¿Las membresías se renuevan mes a mes?",
      content: "Sí, queremos darte la flexibilidad que necesitás."
    },
    {
      title: "¿Cómo es la política de cancelación?",
      content: "Si querés cancelar tu membresía, te pedimos por favor que nos avises por lo menos 15 días antes. Caso contrario se te emitirá la factura del mes entrante."
    },
    {
      title: "¿Se pueden traer invitados?",
      content: "Sí, tenés que reservar una sala o utilizar los espacios de uso común. Por una cuestión de convivencia, no se pueden tener reuniones ni ingresar gente externa a los puestos de trabajo ya que eso podría molestar al resto."
    },
    {
      title: "¿Dónde dejo mi computadora y elementos personales?",
      content: "En los puestos Fijos podés dejar tu computadora todo el tiempo que quieras. Tus cosas personales pueden ir en un locker o en el escritorio, excepto alimentos y bebidas. Si tenés un puesto Eventual o Flexible, la idea es que te lleves todo cuando te vayas o lo guardes en tu locker."
    },
    {
      title: "¿Puedo ir en bici?",
      content: "Sí, en Colaboro tenemos espacio para dejar bicicletas."
    }
  ]
};

export default function FAQs() {
  return (
    <div>
      <Faq
        data={data}
        styles={styles}
      />
    </div>
  );
}


const styles = {
  bgColor: "none",
  titleTextColor: "white",
  rowTitleColor: "white",
  rowContentColor: "white",
  arrowColor: "white",
  titleTextSize: "20px",
  rowTitleTextSize: "medium",
  rowContentTextSize: "medium"
};