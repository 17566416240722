import CardWrapper, { wrapperWidth } from "core/components/CardWrapper";
import PropTypes from "prop-types";
import React from "react";
import { Form } from "reactstrap";
import FormInput from "core/components/FormInput";
import Button from "core/components/Button";

const ResetPasswordForm = ({
  passwordCredentials,
  email,
  onSubmit,
  onPasswordCredentialsUpdated,
  submitting
}) => {
  const updatepasswordCredentials = e =>
    onPasswordCredentialsUpdated({
      ...passwordCredentials,
      [e.target.id]: e.target.value
    });
  const handleSubmit = e => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <CardWrapper
      title={<img src="/colaboro-logo.png" alt="Colaboro" className="w-50 h-auto" />}
      width={wrapperWidth.s}
    >
      <Form onSubmit={handleSubmit}>
        <h4 className="text-center">Cambio de contraseña</h4>
        <h5 className="text-center">Email: {email}</h5>
        <FormInput
          id="password"
          type="password"
          label="Contraseña"
          value={passwordCredentials.password}
          onChange={updatepasswordCredentials}
          mandatory
        />
        <FormInput
          id="confirmPassword"
          type="password"
          label="Confirmar contraseña"
          value={passwordCredentials.confirmPassword}
          onChange={updatepasswordCredentials}
          mandatory
        />
        <div className="mx-auto d-flex flex-row flex-wrap justify-content-end">
          <Button
            label="Cambiar contraseña"
            type="submit"
            className="mt-2 btn btn-primary"
            showSpinner={submitting}
            onClick={handleSubmit}
          />
        </div>
      </Form>
    </CardWrapper>
  );
};

ResetPasswordForm.propTypes = {
  passwordCredentials: PropTypes.PropTypes.shape({
    password: PropTypes.string.isRequired,
    confirmPassword: PropTypes.string.isRequired
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPasswordCredentialsUpdated: PropTypes.func.isRequired
};

export default ResetPasswordForm;
