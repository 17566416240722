import React from "react";
import PropTypes from "prop-types";
import Button from "core/components/Button";
import FormInput from "core/components/FormInput";

const LoginForm = ({
  loading,
  credentials,
  onSubmit,
  onCredentialUpdated,
  onForgotPassword
}) => {
  const updateCredential = e =>
    onCredentialUpdated({
      ...credentials,
      [e.target.id]: e.target.value
    });
  const handleSubmit = e => {
    e.preventDefault();
    onSubmit();
  };

  const handleForgotPassword = () => onForgotPassword();

  return (
    <form onSubmit={handleSubmit}>
      <FormInput
        id="email"
        type="text"
        label="Email"
        value={credentials.email}
        onChange={updateCredential}
        mandatory
      />
      <FormInput
        id="password"
        type="password"
        label="Contraseña"
        value={credentials.password}
        onChange={updateCredential}
        mandatory
      />
      <div className="mx-auto d-flex flex-row flex-wrap justify-content-end">
        <Button
          label="Olvidé la contraseña"
          type="button"
          className="mt-2 btn btn-outline-primary"
          onClick={handleForgotPassword}
        />
        <Button
          label="Ingresar"
          type="submit"
          className="mt-2 ml-2 btn btn-primary"
          showSpinner={loading}
        />
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  credentials: PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCredentialUpdated: PropTypes.func.isRequired,
  onForgotPassword: PropTypes.func.isRequired
};

export default LoginForm;
