import MembersListPage from "pages/members/MembersListPage";
import MemberEditPage from "pages/members/MemberEditPage";
import CustomerAndMemberPage from "pages/customers/CustomerAndMemberPage";
import React from "react";
import { Route, Switch } from "react-router-dom";

const MembersRoutes = () => (
    <Switch>
        <Route exact path="/members" component={MembersListPage} />
        <Route exact path="/members/create-simple" component={MemberEditPage} />
        <Route exact path="/members/edit/:id" component={MemberEditPage} />
        <Route exact path="/members/create" component={CustomerAndMemberPage} />
    </Switch>
);

export default MembersRoutes;
