import React from "react";
import "./TestBanner.scss";

export default class TestBanner extends React.PureComponent {
  render() {
    return (
        <div className="test-banner">TEST ENVIRONMENT - NOT FOR PRODUCTION USE</div>
    );
  }
}
