import React from "react";
import CustomerAndMemberForm from "components/customers/CustomerAndMemberForm";
import Customer from "entities/customer";
import { withRouter } from "react-router-dom";
import makeValidable from "core/components/Validable";

const ValidableCustomerAndMemberForm = makeValidable(CustomerAndMemberForm);

class CustomerAndMemberPage extends React.Component {
    constructor(props) {
        super(props);
        this.validableRef = React.createRef();
        this.state = {
            customer: Customer.getDefaultInputWithMember(),
            taxResponsibilityTypes: [],
            customerTypes: [],
            submitting: false,
            showValidations: false,
            loading: true
        };

        this.navigateBack = this.navigateBack.bind(this);
    }

    customerId = () => {
        return this.props.match.params.id;
    };

    async componentDidMount() {
        this.setState({
            taxResponsibilityTypes: await Customer.getTaxResponsibilityTypes(),
            customerTypes: await Customer.getCustomerTypes(),
            loading: false
        });
    }

    componentDidUpdate(prevProps) {
        if (!this.customerId() && prevProps.match.params.id) {
            this.setState({ customer: Customer.getDefaultInputWithMember() });
        }
    }

    save = () => {
        const getNewPath = () => {
            if (this.props.location.pathname.startsWith("/customers")) {
                return "/customers";
            }
            else if (this.props.location.pathname.startsWith("/members")) {
                return "/members";
            }
            else {
                throw new Error("Invalid path");
            }
        };
        this.setState({ showValidations: true }, async () => {
            if (this.validableRef.current.isValid()) {
                this.setState({ submitting: true });
                Customer.createWithMember(this.state.customer)
                    .then(() => this.props.history.push(getNewPath()))
                    .catch(() => this.setState({ submitting: false }));
            };
        });
    };

    navigateBack = () => {
        this.props.history.goBack();
    };

    render() {
        const { showValidations, customer, submitting, taxResponsibilityTypes, customerTypes, loading } = this.state;

        return (
            <ValidableCustomerAndMemberForm
                ref={this.validableRef}
                showValidations={showValidations}
                customer={customer}
                onSubmit={this.save}
                onCancel={this.navigateBack}
                onCustomerUpdated={customer => this.setState({ customer })}
                submitting={submitting}
                taxResponsibilityTypes={taxResponsibilityTypes}
                customerTypes={customerTypes}
                loading={loading}
            />
        );
    }
}

export default withRouter(CustomerAndMemberPage);
