import React from "react";
import PropTypes from "prop-types";

const DetailRow = ({ label, value }) => (
  <div className="row justify-content-center">
    <div className="col-2 font-weight-bold">{label}</div>
    <div className="col-4 text-secondary">{value}</div>
  </div>
);

DetailRow.protoTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string
};

export default DetailRow;
