import { Fragment } from "react";
import CardWrapper, { wrapperWidth } from "core/components/CardWrapper";
import Actions from "core/components/FormDefaultActions";
import FormInput from "core/components/FormInput";
import { Form } from "reactstrap";
import Spinner from "core/components/Spinner";
import Dropdown from "core/components/FormDropdown";
import CustomDatePicker from "core/components/FormDatePicker";
import { formatDateWithTime } from "core/util/dateUtilities";
import moment from "moment";
import { isAdminUser } from "authorization/roles";

const BookingData = ({ booking, onUpdate, bookingResources, members, isEdit }) => {
    const updateBooking = newValue =>
        onUpdate({
            ...booking,
            ...newValue
        });
    return (
        <div>
            <div>
                <Dropdown
                    placeholder="Seleccioná un recurso..."
                    label="Recurso"
                    items={bookingResources.map(x => ({ id: x.id, label: x.name }))}
                    selectedId={booking.bookingResourceId}
                    onChange={bookingResourceId => updateBooking({ bookingResourceId })}
                    mandatory
                />
            </div>
            <div>
                <CustomDatePicker
                    label="Fecha y hora"
                    selected={booking.startDate ? moment.utc(booking.startDate).toDate() : null}
                    onChange={startDate => {
                        if (startDate) {
                            updateBooking({ startDate });
                        }
                        else {
                            updateBooking({ startDate: null });
                        }
                    }}
                    showTimeSelect={true}
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Hora"
                    dateFormat={"MMMM d, yyyy h:mm aa"}
                    mandatory
                />
            </div>
            <div>
                <FormInput
                    id="duration"
                    label="Duración (en minutos)"
                    value={booking.duration}
                    onChange={e =>
                        updateBooking({
                            duration: parseInt(e.target.value || 0)
                        })
                    }
                    mandatory
                    type="number"
                    min="15"
                    step="15"
                />
            </div>
            {isAdminUser() &&
                <Fragment>
                    <div>
                        <FormInput
                            id="notes"
                            label="Notas"
                            value={booking.notes || ""}
                            onChange={e =>
                                updateBooking({
                                    notes: e.target.value
                                })
                            }
                            mandatory
                        />
                    </div>
                    {!isEdit &&
                        <div>
                            <Dropdown
                                placeholder="Seleccioná un miembro... (opcional)"
                                label="Miembro"
                                items={members.map(x => ({ id: x.id, label: x.name }))}
                                selectedId={booking.memberForId}
                                onChange={memberForId => updateBooking({ memberForId })}
                            />
                        </div>}
                </Fragment>
            }
        </div>
    );
};

const BookingForm = ({ booking, onSubmit, onCancel, onDelete, onBookingUpdated, submitting, bookingResources, availableminutes, members, isEdit, loading }) => {
    const handleSubmit = e => {
        e.preventDefault();
        onSubmit();
    };

    const title = isEdit ? "Editar reserva" : "Crear reserva";

    return (
        <CardWrapper title={title} width={wrapperWidth.s}>
            <Spinner show={loading} size="fa-3x" />
            {!loading &&
                <Form onSubmit={handleSubmit}>
                    <BookingData
                        booking={booking}
                        onUpdate={onBookingUpdated}
                        bookingResources={bookingResources}
                        members={members}
                        isEdit={isEdit} />
                    {!isAdminUser() &&
                        <h5>Tiempo de reserva disponible del mes: {moment.utc(moment.duration(availableminutes, "minutes").asMilliseconds()).format("HH:mm")}</h5>}
                    <Actions
                        onSubmit={handleSubmit}
                        onCancel={onCancel}
                        submitting={submitting}
                        onDelete={onDelete}
                        deleteEnabled={isEdit}
                        deleteOptions={isEdit ? {
                            entityName: "la reserva de",
                            instanceName: formatDateWithTime(booking.startDate)
                        } : null}

                    />
                </Form>}
        </CardWrapper>
    );
};

export default BookingForm;
