import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import "./Validable.scss";

const ValidableFeedback = ({ invalid, text }) =>
  invalid ? <div className="validation-feedback">{text || "Campo requerido"}</div> : null;

ValidableFeedback.propTypes = {
  invalid: PropTypes.bool.isRequired,
  text: PropTypes.string
};

export { ValidableFeedback, makeValidable };

export default function makeValidable(WrappedComponent) {
  return class Validable extends React.Component {
    constructor(props) {
      super(props);
      this.containerRef = React.createRef();
    }

    displayName = `Validable(${WrappedComponent.displayName ||
      WrappedComponent.name ||
      "Component"})`;

    static propTypes = {
      showValidations: PropTypes.bool.isRequired
    };

    isValid() {
      if (!this.props.showValidations) {
        throw Error(
          "The property 'showValidations' must be true before calling this method. Make sure to call it within 'callback' in containers 'setState(updater, callback)'"
        );
      }
      const invalidElements = this.getInvalidElements();
      if (invalidElements.length > 0) {
        invalidElements[0].scrollIntoView({ behavior: "smooth", block: "start" });
        for (let index = 0; index < invalidElements.length; index++) {
          const invalidElement = invalidElements[index];
          let input = invalidElement.querySelector("input");
          if (!input) {
            input = invalidElement.querySelector("textarea");
          }
          let errorMessage = input ? input.placeholder : null;
          if (!errorMessage) {
            const dropdown = invalidElement.querySelector(".react-select__placeholder");
            errorMessage = dropdown ? dropdown.innerHTML : invalidElement.getAttribute("placeholder");
          }
          toast.error(errorMessage, "", 7 * 1000);
        }
      }
      return invalidElements.length === 0;
    }

    getInvalidElements() {
      return this.containerRef.current.querySelectorAll("div.show-validations .invalid");
    }

    render() {
      const { showValidations, ...passThroughProps } = this.props;

      return (
        <div
          ref={this.containerRef}
          className={showValidations ? "show-validations" : "hide-validations"}
        >
          <WrappedComponent {...passThroughProps} />
        </div>
      );
    }
  };
}
