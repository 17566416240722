import { ReactNode } from "react";

interface Props {
  show: boolean;
  children?: ReactNode;
  size?: string;
}

const Spinner = ({ show, children, size }: Props) => {
  if (!show)
    return null;

  return (
    <span>
      &nbsp;
      <i className={`fa fa-spin fa-spinner ${size || ""}`}>{children}</i>
    </span>
  );
};

export default Spinner;
