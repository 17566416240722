import React from "react";
import PropTypes from "prop-types";
import { getLoginData } from "core/storage/localStorage";

const FilterForm = ({ onSearch, children }) => (
  <form
    noValidate
    onSubmit={e => { e.preventDefault(); onSearch(); }}
  >
    {children}
  </form>
);

FilterForm.propTypes = {
  onSearch: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired
};

const FilterInputRow = ({ label, children, labelClassName }) => (
  <div className="row pb-1">
    <div className={"col-4 col-sm-2"} style={{ margin: "auto 0" }}>
      <label className={labelClassName || ""} style={{ marginBottom: 0 }}>{label}</label>
    </div>
    <div className="col-8 col-sm-4 text-secondary">
      {children}
    </div>
  </div>
);

FilterInputRow.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired
};

const FilterSearchRow = () => (
  <FilterInputRow label="">
    <button
      type="submit"
      label="Buscar"
      className="btn btn-outline-primary"
    >
      Buscar
    </button>
  </FilterInputRow>
);

const FilterBasicSearch = ({ onSearch, defaultValue = "" }) => {
  return (
    <div className="btn-toolbar-item input-with-icon">
      <input
        defaultValue={defaultValue}
        type="text"
        className="form-control input-block"
        placeholder="Buscar"
        onKeyPress={e => {
          if (e.charCode === 13) onSearch(e.target.value);
        }}
      />
      <span className="icon icon-magnifying-glass" />
    </div>
  );
};

FilterBasicSearch.propTypes = {
  onSearch: PropTypes.func.isRequired
};

const CreateButton = ({ onCreate, text }) => (
  <button
    type="button"
    className="btn btn-primary create-button"
    onClick={e => onCreate()}
  >
    {text || "Crear"}
  </button>
);

CreateButton.propTypes = {
  onCreate: PropTypes.func.isRequired,
  text: PropTypes.string
};

const CustomButton = ({ allowed, text, onClick }) => {
  const loginData = getLoginData();
  if (!loginData) {
    return null;
  }

  return (
    <button
      type="button"
      className="btn btn-link p-0 mt-1"
      onClick={e => onClick()}
      disabled={loginData && !allowed.includes(loginData.plan.name)}
    >
      {text}
    </button>
  );
};

CustomButton.protTypes = {
  allowed: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};


const ImportButton = ({ onClick }) => (
  <button
    type="button"
    className="btn btn-primary mr-1"
    onClick={e => onClick()}
  >
    Importar
  </button>
);

ImportButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

const TableActions = ({ children }) => {
  return <div className="table-actions">{children}</div>;
};

TableActions.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export { FilterForm, FilterInputRow, FilterSearchRow, FilterBasicSearch, CreateButton, CustomButton, ImportButton, TableActions };
