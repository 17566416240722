import { PricesPage } from "pages/management/Prices";
import { BroadcastPage } from "pages/management/Broadcast";
import React from "react";
import { Route, Switch } from "react-router-dom";

const ManagementRoutes = () => (
    <Switch>
        <Route exact path="/management/prices" component={PricesPage} />
        <Route exact path="/management/broadcast" component={BroadcastPage} />
    </Switch>
);

export default ManagementRoutes;
