import React from "react";
import PropTypes from "prop-types";
import Input from "./Input";

class FormInput extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    formGroupClassName: PropTypes.string,
    id: PropTypes.any
  };

  render() {
    const { id, label, formGroupClassName, mandatory, ...rest } = this.props;

    return (
      <div className={`"position-relative form-group ${formGroupClassName}`}>
        {label && label !== "" &&
          <label htmlFor={id} className="form-label">
            {label}{mandatory && " *"}
          </label>}
        <Input id={id} placeholder={label} mandatory={mandatory} {...rest} />
      </div>
    );
  }
}

export default FormInput;
