import React from "react";
import PropTypes from "prop-types";
import LinkButton from "core/components/LinkButton";
import { useHistory } from "react-router-dom";
import { formatDateWithTime } from "core/util/dateUtilities";
import { isAdminUser } from "authorization/roles";

const Row = ({ booking }) => {
    const history = useHistory();
    const {
        id,
        bookingResource,
        member,
        startDate,
        endDate,
        notes
    } = booking;
    return (
        <tr>
            <td><LinkButton id={id} label={bookingResource.name} onClick={() => history.push(`/bookings/edit/${id}`)} /></td>
            <td>{member.name}</td>
            <td>{formatDateWithTime(startDate)}</td>
            <td>{formatDateWithTime(endDate)}</td>
            {isAdminUser() && <td>{notes}</td>}
        </tr>
    );
};

Row.propTypes = {
    booking: PropTypes.any.isRequired
};

export default Row;
