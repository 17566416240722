import React from "react";
import CustomerForm from "components/customers/CustomerForm";
import Customer from "entities/customer";
import { withRouter } from "react-router-dom";
import makeValidable from "core/components/Validable";

const ValidableCustomerForm = makeValidable(CustomerForm);

class CustomerEditPage extends React.Component {
    constructor(props) {
        super(props);
        this.validableRef = React.createRef();
        this.state = {
            customer: Customer.getDefaultInput(),
            taxResponsibilityTypes: [],
            customerTypes: [],
            submitting: false,
            showValidations: false,
            loading: true
        };

        this.navigateBack = this.navigateBack.bind(this);
    }

    customerId = () => {
        return this.props.match.params.id;
    };

    async componentDidMount() {
        const state = {
            taxResponsibilityTypes: await Customer.getTaxResponsibilityTypes(),
            customerTypes: await Customer.getCustomerTypes(),
            loading: false
        };
        const id = this.customerId();
        if (id) {
            state.customer = await Customer.getById(id);
        }
        this.setState(state);
    }

    componentDidUpdate(prevProps) {
        if (!this.customerId() && prevProps.match.params.id) {
            this.setState({ customer: Customer.getDefaultInput() });
        }
    }

    save = () => {
        this.setState({ showValidations: true }, async () => {
            if (this.validableRef.current.isValid()) {
                this.setState({ submitting: true });
                const id = this.customerId();
                const savePromise = id
                    ? Customer.update(id, this.state.customer)
                    : Customer.create(this.state.customer);
                savePromise
                    .then(() => this.props.history.push("/customers"))
                    .catch(() => this.setState({ submitting: false }));
            };
        });
    };

    navigateBack = () => {
        this.props.history.goBack();
    };

    render() {
        const { showValidations, customer, submitting, taxResponsibilityTypes, customerTypes, loading } = this.state;

        return (
            <ValidableCustomerForm
                ref={this.validableRef}
                showValidations={showValidations}
                customer={customer}
                onSubmit={this.save}
                onCancel={this.navigateBack}
                onCustomerUpdated={customer => this.setState({ customer })}
                submitting={submitting}
                taxResponsibilityTypes={taxResponsibilityTypes}
                customerTypes={customerTypes}
                isEdit={!!this.customerId()}
                loading={loading}
            />
        );
    }
}

export default withRouter(CustomerEditPage);
