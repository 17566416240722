import React from "react";
import PropTypes from "prop-types";

const LinkButton = ({ id, label, onClick }) => (
  <button type="button" className="btn btn-link p-0" onClick={e => onClick(id)}>
    {label}
  </button>
);

LinkButton.propTypes = {
  id: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired
};

export default LinkButton;
