import { isPasswordValid, resetPassword } from "core/fetch/auth";
import makeValidable from "core/components/Validable";
import queryString from "query-string";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import ResetPasswordForm from "./ResetPasswordForm";

const ValidableResetPasswordForm = makeValidable(ResetPasswordForm);

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.validableRef = React.createRef();
    this.state = {
      credentials: {
        password: "",
        confirmPassword: ""
      },
      submitting: false,
      email: undefined,
      token: undefined,
      showValidations: false
    };
  }

  componentDidMount() {
    const searchObj = queryString.parse(this.props.location.search);
    // TODO: Display error if email or token are missing
    this.setState({ email: searchObj.email, token: searchObj.token });
  }

  validatePassword() {
    if (!isPasswordValid(this.state.credentials.password)) {
      toast.error("Password must be at least 8 characters");
      return false;
    }
    if (this.state.credentials.password !== this.state.credentials.confirmPassword) {
      toast.error("Passwords do not match!");
      return false;
    }
    return true;
  }

  handleCredentialsChange = newCredentials => {
    this.setState({
      credentials: { ...newCredentials }
    });
  };

  onSubmit = () => {
    this.setState({ showValidations: true }, async () => {
      if (this.validableRef.current.isValid() && this.validatePassword()) {
        this.setState({ submitting: true });
        var data = {
          password: this.state.credentials.password,
          token: this.state.token
        };
        try {
          await resetPassword(data);
          toast.success("Contraseña cambiada con éxito");
          this.props.history.push("/login");
        } catch (e) {
          const errorMessage = (e instanceof Error || e instanceof TypeError) ? "Ha ocurrido un error" : e;
          toast.error(errorMessage, "", 3 * 1000);
          this.setState({ submitting: false });
        }
      };
    });
  };

  render() {
    return (
      <ValidableResetPasswordForm
        ref={this.validableRef}
        showValidations={this.state.showValidations}
        passwordCredentials={this.state.credentials}
        email={this.state.email}
        onSubmit={this.onSubmit}
        onPasswordCredentialsUpdated={this.handleCredentialsChange}
        submitting={this.state.submitting}
      />
    );
  }
}

export default withRouter(ResetPassword);
