import ServicesListPage from "pages/services/ServicesListPage";
import React from "react";
import { Route, Switch } from "react-router-dom";
import ServiceEditPage from "./ServiceEditPage";

const ServicesRoutes = () => (
    <Switch>
        <Route exact path="/services/create" component={ServiceEditPage} />
        <Route exact path="/services/edit/:id" component={ServiceEditPage} />
        <Route exact path="/services/:id?" component={ServicesListPage} />
    </Switch>
);

export default ServicesRoutes;
