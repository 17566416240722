import UnlocksListPage from "pages/unlocks/UnlocksListPage";
import React from "react";
import { Route, Switch } from "react-router-dom";

const UnlockRoutes = () => (
    <Switch>
        <Route exact path="/unlocks/:id?" component={UnlocksListPage} />
    </Switch>
);

export default UnlockRoutes;
